<template>
  <VCard
    v-if="session"
    class="session-card"
    v-on="events"
  >
    <header>
      <VListItem dense>
        <VListItemContent>
          <VListItemSubtitle
            v-if="!session.is_active && !session.is_accepted"
            class="text-overline accent--text text-wrap"
          >
            {{ $t('no_training.one') }}
          </VListItemSubtitle>
          <VListItemSubtitle
            v-else
            :class="['text-overline text-wrap', status.classNames]"
          >
            {{ $t(status.title) }} {{ session.is_accepted ? acceptedAt : '' }}
          </VListItemSubtitle>
        </VListItemContent>
        <VListItemIcon v-if="session.is_started && refreshable">
          <VBtn
            icon
            :title="$t('update.one') + ' ' + $t('list_lessons.one').toLowerCase()"
            :loading="refreshing"
            small
            @click.stop="sessionRefresh"
          >
            <VIcon>
              refresh
            </VIcon>
          </VBtn>
        </VListItemIcon>
      </VListItem>
      <VDivider />
    </header>

    <div class="session-card__body">
      <VListItem>
        <VListItemContent>
          <VListItemTitle class="display-1 text-wrap">
            {{ session.course_title }}
          </VListItemTitle>

          <VListItemSubtitle class="accent--text text-wrap">
            <p v-if="isAvailableTrainingRaw && !session.settings?.ignore_session_dates">
              {{ isAvailableTrainingRaw }}
            </p>
            <p v-if="isAvailableSummativeRaw && !session.settings?.ignore_summative_dates">
              {{ isAvailableSummativeRaw }}
            </p>
          </VListItemSubtitle>
        </VListItemContent>

        <VListItemAvatar
          :class="avatarClasses"
          :size="size"
        >
          <VProgressCircular
            v-if="session.is_started && !session.is_accepted"
            :title="progressTitle"
            color="success"
            :size="size"
            :value="session.progress"
          >
            <span class="caption text--primary">{{ session.steps_accepted }} / {{ session.steps_count }}</span>
            <VImg
              v-if="session.course_image"
              class="progress-circular__image"
              :src="`/${session.course_image}`"
              cover
            />
          </VProgressCircular>
          <VIcon
            v-else-if="session.is_accepted"
            large
            dark
          >
            done
          </VIcon>

          <VImg
            v-else-if="session.course_image"
            :src="`/${session.course_image}`"
            :alt="session.course_title"
            cover
          />

          <VIcon
            v-else
            class="text--primary"
            large
          >
            school
          </VIcon>
        </VListItemAvatar>
      </VListItem>

      <VCardText
        v-if="teachers.length"
        class="pt-0"
      >
        <label>{{ $t('teachers.other') }}: </label>
        <RouterLink
          v-for="(teacher, idx) in teachers"
          :key="teacher.id"
          :to="`/users/${teacher.id}`"
        >
          {{ teacher.title + (idx !== (teachers.length -1) ? ',' : '') }}
        </RouterLink>
      </VCardText>

      <VListItem
        v-if="isShowDocuments"
        dense
        @click.stop="goToLibrary"
      >
        <VListItemIcon>
          <VIcon>
            library_books
          </VIcon>
        </VListItemIcon>

        <VListItemContent>
          <VListItemSubtitle>{{ $t('$app.documents_library') }}</VListItemSubtitle>
        </VListItemContent>
      </VListItem>
    </div>

    <VCardActions
      v-if="session.is_active && !session.is_completed"
      tag="footer"
      class="actions"
    >
      <VBtn
        v-if="!starting && session.is_started"
        color="primary"
        :loading="continuing"
        @click.stop="sessionContinue"
      >
        <VIcon left>
          play_arrow
        </VIcon>
        {{ $t('continue_training.one') }}
      </VBtn>

      <VBtn
        v-else
        color="accent"
        :loading="starting"
        @click.stop="sessionStart"
      >
        <VIcon left>
          play_arrow
        </VIcon>
        {{ $t('start_training.one') }}
      </VBtn>

      <slot />
    </VCardActions>
  </VCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { formatInt } from '@/utils/common'
import { UDate } from '@/utils/date'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import { prepareSessionStatus } from '@/utils/status'

const units = ['lessons.one', 'lessons.two', 'lessons.many']

export default {
  name: 'SessionCard',

  props: {
    session: {
      type: Object,
      default: null
    },

    refreshable: {
      type: Boolean,
      default: false
    },

    isShowDocuments: {
      type: Boolean,
      default: false
    },

    teachers: {
      type: Array,
      default: () => []
    },

    to: {
      type: [Object, String],
      default: ''
    }
  },

  data () {
    return {
      starting: false,
      continuing: false,
      refreshing: false
    }
  },

  computed: {
    ...mapGetters({
      now: getters.NOW
    }),

    size () {
      return this.$vuetify.breakpoint.xs ? 48 : 56
    },

    progressTitle () {
      if (!this.session?.is_started || this.session?.is_accepted) {
        return ''
      }

      const preparedUnits = units.map(unit => {
        return this.$t(unit).toLowerCase()
      })

      return `${this.$t('$app.completed')}: ${formatInt(this.session.steps_accepted, preparedUnits)} ${this.$tc('out_of.one', 3)} ${this.session.steps_count}`
    },

    acceptedAt () {
      return UDate.formatDate(this.session.accepted_at)
    },

    isAvailableTrainingRaw () {
      const sessionStartsAt = new UDate(this.session?.settings?.session_starts_at).valueOf()
      const sessionEndsAt = new UDate(this.session?.settings?.session_ends_at).valueOf()
      let str = ''

      if (!sessionStartsAt && !sessionEndsAt) { return '' }

      if (sessionStartsAt && (sessionStartsAt > Date.parse(this.now))) {
        // Обучение будет доступно только…
        str += this.$t('training_possible_will_be_only.one')
      } else if (sessionEndsAt && (sessionEndsAt + UDate.dayInMilliseconds < Date.parse(this.now))) {
        // Обучение было доступно только…
        str += this.$t('training_possible_was_only.one')
      } else {
        // Обучение можно пройти только…
        str += this.$t('training_possible_only.one')
      }

      // …С <date> по <date>
      if (sessionStartsAt === sessionEndsAt) {
        str += ` ${UDate.formatDateTime(sessionStartsAt)}`
        return str
      }
      if (sessionStartsAt) {
        str += ` ${this.$t('from.one').toLowerCase()} ${UDate.formatDateTime(sessionStartsAt)}`
      }

      if (sessionEndsAt) {
        str += ` ${this.$t('to.one').toLowerCase()} ${UDate.formatDateTime(sessionEndsAt - UDate.minuteInSeconds)}`
      }

      return str
    },

    isAvailableSummativeRaw () {
      const summativeStartsAt = new UDate(this.session?.settings?.summative_starts_at).valueOf()
      const summativeEndsAt = new UDate(this.session?.settings?.summative_ends_at).valueOf()
      let str = ''

      if (!summativeStartsAt && !summativeEndsAt) { return '' }

      if (summativeStartsAt && (summativeStartsAt > Date.parse(this.now))) {
        // Итоговый (оценочный) урок можно будет пройти…
        str += this.$t('final_test_possible_will_be_complete_only.one')
      } else if (summativeEndsAt && (summativeEndsAt + UDate.dayInMilliseconds < Date.parse(this.now))) {
        // Итоговый (оценочный) урок можно было пройти…
        str += this.$t('final_test_possible_was_complete_only.one')
      } else {
        // Итоговый (оценочный) урок можно пройти…
        str += this.$t('final_test_possible_complete_only.one')
      }

      // …С <date> по <date>
      if (summativeStartsAt === summativeEndsAt) {
        str += ` ${UDate.formatDateTime(summativeStartsAt)}`
        return str
      }
      if (summativeStartsAt) {
        str += ` ${this.$t('from.one').toLowerCase()} ${UDate.formatDateTime(summativeStartsAt)}`
      }

      if (summativeEndsAt) {
        str += ` ${this.$t('to.one').toLowerCase()} ${UDate.formatDateTime(summativeEndsAt - UDate.minuteInSeconds)}`
      }

      return str
    },

    avatarClasses () {
      const dafaultClasses = 'session-card__avatar'
      if (!this.session) {
        return dafaultClasses
      }

      return {
        [dafaultClasses]: true,
        'session-card__avatar--started': this.session.is_started && !this.session.is_accepted,
        'session-card__avatar--accepted': this.session.is_accepted,
        'session-card__avatar--course-image': this.session.course_image && !this.session.is_started && !this.session.is_accepted,
      }
    },

    events () {
      return this.to ? { click: this.goToSession } : {}
    },

    status () {
      const code = this.session?.status?.code
      return prepareSessionStatus(code)
    }
  },

  methods: {
    ...mapActions({
      continueSession: actions.CONTINUE_SESSION,
      startSession: actions.START_SESSION
    }),

    goToSession () {
      this.$router.push(this.to).catch(() => {})
    },

    goToLibrary () {
      const tab = this.session.documents_count ? 'documents' : 'textbooks'
      const path = {
        name: 'session',
        params: { sessionID: this.session.id },
        query: { tab }
      }

      this.$router.push(path).catch(() => {})
    },

    handleResponse ({ session, step }) {
      if (!session && session.id) {
        return
      }

      let path = { name: 'session', params: { sessionID: session.id } }
      if (step && step.id) {
        path = { name: 'training', params: { sessionID: session.id, stepID: step.id } }
      }

      this.$router.push(path).catch(() => {})
    },

    async sessionRefresh () {
      this.refreshing = true

      try {
        await this.continueSession(this.session.id)
      } catch (e) {
        //
      } finally {
        this.refreshing = false
      }
    },

    async sessionContinue () {
      this.continuing = true

      try {
        const response = await this.continueSession(this.session.id)
        this.handleResponse(response)
      } catch (e) {
        //
      } finally {
        this.continuing = false
      }
    },

    async sessionStart () {
      this.starting = true

      try {
        const data = new FormData()
        data.append('order', this.session.order_id)
        const opts = {
          sessionId: this.session.id,
          data
        }

        const response = await this.startSession(opts)
        this.handleResponse(response)
      } catch (e) {
        //
      } finally {
        this.starting = false
      }
    }
  }
}
</script>

<style lang="scss">
.session-card {
  height: 100%;
  min-height: 10rem;
  display: flex;
  flex-flow: column nowrap;
}

.session-card__body {
  flex-grow: 1;
}

.session-card__avatar {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid currentColor;
  padding: $spacer;
  align-self: flex-start;

  .v-progress-circular > svg {
    z-index: 1;
  }
}

.session-card__avatar--started,
.session-card__avatar--accepted {
  border-width: 0;
  padding: 0;
}

.session-card__avatar--accepted {
  background: var(--v-success-base);
}

.session-card__avatar--course-image {
  padding: 0;
  border-width: 0;
}

.progress-circular__image {
  position: absolute;
  z-index: -1;
}

.progress-circular__image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 85%);
  z-index: 1;
}
</style>
